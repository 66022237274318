$primary: #a855f7;
$headingColor: #fafafa;
$borderColor: #262626;
$cardBg: #0a0a0a;
$paragraphColor: #a1a1aa;

@mixin inputStyle() {
  color: $headingColor;
  background-color: $cardBg;

  &::placeholder {
    color: $headingColor;
  }
}

@mixin buttonStyle() {
  background-color: $primary;
}

@mixin svgStyle($svgColor) {
  svg {
    path {
      stroke: $svgColor;
    }
  }
}

.jlt-wpspotlight {
  // blog & author page style start
  .jlt-blog-subscribe {
    form {
      > div {
        border-color: $borderColor;
      }
    }

    h3 {
      font-weight: 700;
      color: $headingColor;

      span {
        color: $primary;
      }
    }

    p {
      color: $paragraphColor;
    }

    input {
      @include inputStyle();
    }

    button {
      @include buttonStyle();
    }
  }

  .jlt-blog-category {
    border: 1px solid $borderColor;
    background-color: $cardBg;

    .jlt-blog-category-preview {
      .jlt-blog-tag {
        > div {
          color: #09090b;
        }

        > div span {
          color: $headingColor;
          @include svgStyle($headingColor);
        }
      }

      h1 {
        color: $headingColor;
        font-weight: 700;

        &:hover {
          color: $primary;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        color: $paragraphColor;
      }

      a {
        span {
          color: $primary;
        }

        @include svgStyle($primary);
      }
    }

    .jlt-blog-category-list {
      a {
        h3 {
          color: $headingColor;
          font-weight: 700;

          &:hover {
            color: $primary;
          }
        }
      }

      div {
        span {
          color: $headingColor;
          @include svgStyle($headingColor);
        }
      }
    }
  }

  .jlt-latest-post-title {
    h3 {
      color: $headingColor;
      font-weight: 700;
    }

    a {
      color: $primary;
      @include svgStyle($primary);
    }
  }

  .jlt-latest-posts {
    > div {
      background-color: $cardBg;
      border-radius: 8px;
      border: 1px solid $borderColor;
      padding: 4px;

      a {
        img {
          border-radius: 8px;
        }
      }

      a {
        color: #0a0a0a;
      }

      h3 {
        color: $headingColor;
        font-weight: 700;

        &:hover {
          color: $primary;
        }
      }

      span {
        color: $headingColor;
        @include svgStyle($headingColor);
      }
    }
  }

  .jlt-view-all-blog {
    background-color: $primary;
    margin-bottom: 128px;
  }

  .jlt-single-blog-post-wrapper {
    .jlt-single-blog-post {
      //   background-color: $cardBg;
      background-color: hsl(var(--secondary));
      border: 1px solid $borderColor;

      #featured-image {
        img {
          border-radius: 8px;
          width: 100%;
        }
      }

      .jlt-single-blog-post-author-wrapper {
        span,
        h4 {
          color: $headingColor;
        }

        @include svgStyle($headingColor);
      }

      h1 {
        color: $headingColor;
      }

      .jlt-blog-content {
        margin-top: 48px;

        p {
          margin: 32px 0;
          color: $paragraphColor;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          text-align: justify;

          strong {
            color: $headingColor;
          }
        }

        img,
        figure {
          margin: 40px auto;
          border-radius: 8px;
        }

        ul {
          margin: 0 0 32px 20px;
          list-style: inside;
          list-style-type: auto;

          li {
            color: $paragraphColor;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
          }
        }

        figure.jlt-video-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .jlt-blog-post-features-table {
          border-color: $borderColor;
          margin-top: 32px;
          background-color: $cardBg;
          border-radius: 8px;

          table {
            background-color: $cardBg;
            border-color: $borderColor;
            border-radius: 6px;

            thead {
              tr {
                td {
                  color: $headingColor;
                }
              }
            }

            tbody {
              tr {
                td {
                  color: $paragraphColor;
                  background-color: $cardBg;
                }
              }
            }
          }
        }
      }

      form {
        border-color: $borderColor;

        h4 {
          color: $headingColor;
        }

        p {
          color: $paragraphColor;
        }

        svg {
          path {
            stroke: $borderColor;
            fill: $primary;
          }
        }

        input {
          @include inputStyle();
          border-color: $borderColor;
        }

        button {
          @include buttonStyle();

          svg {
            path {
              stroke: $headingColor;
            }
          }
        }
      }
    }

    .jlt-related-post-list {
      background-color: hsl(var(--secondary));
      border: 1px solid $borderColor;

      h4,
      h5,
      h6 {
        color: $headingColor;
      }

      .jlt-related-post {
        padding: 4px;
        border-radius: 8px;
        border-color: $borderColor;

        img {
          border-radius: 8px;
        }

        span {
          @include svgStyle($headingColor);
        }
      }
    }

    .jlt-blog-post-comments {
      background-color: hsl(var(--secondary));
      border: 1px solid $borderColor;

      h5 {
        color: $headingColor;
      }

      div {
        &:has(form) {
          //   border-color: $borderColor;
          //   background-color: $cardBg;

          form {
            label {
              color: $headingColor;
            }

            input,
            textarea {
              @include inputStyle();
              border-color: $borderColor;
              border-radius: 8px;
            }

            .submit-area {
              input {
                background-color: $primary !important;
              }
            }
          }
        }
      }

      .jlt-blog-post-single-comment {
        background-color: $cardBg;
        border-color: $borderColor;

        h6 {
          color: $headingColor;
        }

        p {
          color: $paragraphColor;
        }

        button {
          background-color: $primary;
          @include svgStyle($headingColor);
        }
      }
    }
  }

  .jlt-popular-blog-posts,
  .jlt-author-blog-post-wrapper,
  .jlt-blog-archive {
    margin-bottom: 128px;

    .jlt-popular-blog {
      background-color: $cardBg;
      padding: 4px;
      border: 1px solid $borderColor;

      span {
        color: $headingColor;
        @include svgStyle($headingColor);
      }

      h3 {
        color: $headingColor;
        font-weight: 700;

        &:hover {
          color: $primary;
        }
      }

      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        color: $paragraphColor;
      }

      a {
        span {
          color: $primary;
        }

        @include svgStyle($primary);
      }
    }

    aside {
      h3 {
        color: $headingColor;

        span {
          color: $headingColor;
        }
      }

      form {
        background-color: $cardBg;
        border: 1px solid $borderColor;

        h4 {
          color: $headingColor;
          font-weight: 700;
        }

        p {
          color: $paragraphColor;
        }

        span {
          svg {
            path {
              stroke: $borderColor;
              fill: $primary;
            }
          }
        }

        input {
          @include inputStyle();
          border-color: $borderColor;
        }

        button {
          background-color: $primary;
        }
      }
    }
  }

  // changelog page style start
  .jlt-wstandard {
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      color: $paragraphColor;
      margin-bottom: 18px;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 700;
      color: $headingColor;
      margin: 24px 0;
    }

    ul {
      li {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        color: $paragraphColor;

        a {
          color: $primary;
        }
      }
    }
  }

  // changelog page style end

  // author page style
  .jlt-author-name {
    font-weight: 700;
    color: $headingColor;

    span {
      color: $primary;
    }
  }

  .jlt-text-\[\#1D2939\] {
    color: $headingColor;
  }

  .jlt-bg-\[\#FFFFFF\],
  .jlt-bg-white {
    background: $cardBg;
  }

  input {
    @include inputStyle();
  }

  .jlt-border-\[\#F2F4F7\] {
    border-color: $borderColor;
  }

  .jlt-border {
    border-color: hsl(var(--background));
  }

  .jlt-bg-\[--primary\] {
    background: hsl(var(--secondary)) !important;
  }
}
